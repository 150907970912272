<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">
        {{ $t("createOrg.title") }}
      </h1>

      <b-button class="mr-2" pill variant="primary" @click="$router.go(-1)">
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>
    <b-card class="shadow-sm">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="handleSubmit(create)">
          <ValidationProvider
            v-slot="{ errors, valid }"
            :customMessages="messages"
            :rules="{
              regex: /^[^@]+$/,
              required: true,
            }"
            name="Name"
          >
            <b-form-group id="group-name" label-for="name">
              <template v-slot:label>
                {{ $t("createOrg.orgName") }} <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="name"
                v-model="name"
                :placeholder="$t('createOrg.orgNamePlaceholder')"
                :state="getValidationState(errors, valid)"
                data-cy="new-organization-name"
              />
              <b-form-invalid-feedback id="live-feedback-first-name">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="d-flex justify-content-end">
            <b-button
              :disabled="invalid"
              data-cy="new-organization-submit-button"
              pill
              type="submit"
              variant="primary"
            >
              {{ $t("createOrg.button") }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { regex, required } from "vee-validate/dist/rules";

extend("regex", regex);
extend("required", required);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "CreateOrganization",
  data() {
    return {
      name: "",
      messages: {
        regex: "Organization name cannot contain '@'!",
      },
    };
  },
  methods: {
    async create() {
      this.loading = true;

      try {
        await this.$http.post(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/`,
          {
            name: this.name,
          },
          {
            errors: {
              409: () => true,
            },
          }
        );
        this.$root.$bvToast.toast(this.$t("createOrg.created"), {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        await this.$router.push({ path: "/admin/organizations" });
      } catch ({ response }) {
        if (response.status === 409) {
          this.$refs.observer.setErrors({
            Name: [response.data.message],
          });
        } else if (response) console.log(response);
      }

      this.loading = false;
    },
    // Validate form
    getValidationState(errors, valid) {
      return errors[0] ? false : valid ? true : null;
    },
  },
};
</script>

<style></style>
