var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"my-4 d-flex align-items-center justify-content-between"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t("createOrg.title"))+" ")]),_c('b-button',{staticClass:"mr-2",attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-icon-arrow90deg-left',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1)],1),_c('b-card',{staticClass:"shadow-sm"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('ValidationProvider',{attrs:{"customMessages":_vm.messages,"rules":{
            regex: /^[^@]+$/,
            required: true,
          },"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-form-group',{attrs:{"id":"group-name","label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("createOrg.orgName"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","placeholder":_vm.$t('createOrg.orgNamePlaceholder'),"state":_vm.getValidationState(errors, valid)},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-first-name"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"disabled":invalid,"pill":"","type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("createOrg.button"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }